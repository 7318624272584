const QUERYPARAMS = {
  SEARCH: "search",
  FAMILY: "family",
  BRAND: "brand",
  SEASON: "season",
  SIZE: "size",
  COLOR: "color",
  STOCK: "hasStock",
  VISIBILITY: "visibility",
  CLIMATOLOGY: "climatology",
  DATE: "date",
  MARKET: "market",
  PAGE: "page",
  PERPAGE: "perPage",
  GROUP: "group",
  FEATUREVALUE: "featureValue",
  BRAND_ORIGIN: "brandOrigin",
  REFERENCE: "reference",
};

export type QueryParameter = undefined | null | string | string[];

const read = (locationSearch: string): Record<string, string | string[]> => {
  const searchParams = new URLSearchParams(locationSearch);

  return Array.from(searchParams.keys()).reduce((acc, key) => {
    const value = searchParams.get(key);

    return {
      ...acc,
      ...(value && { [key]: value.includes(",") ? value.split(",") : value }),
    };
  }, {});
};

const produce = (
  params: Record<string, string | string[]>,
  paramsToUpdate: Record<string, QueryParameter>,
): Record<string, string> => {
  const currentParamKeys = Object.keys(paramsToUpdate);

  if (currentParamKeys.length === 0) {
    throw new Error("You cannot provide an empty object as the setParam parameter");
  }

  const updatedParams = {
    ...params,
    ...paramsToUpdate,
  };

  return Object.keys(updatedParams).reduce(
    (acc: Record<string, string>, name: string) => {
      const value = updatedParams[name];

      return {
        ...acc,
        ...(!(value === null || value === undefined || (Array.isArray(value) && value.length === 0)) && {
          [name]: Array.isArray(value) ? value.join(",") : value,
        }),
      };
    },
    {} as Record<string, string>,
  );
};

export { produce, read };

export default QUERYPARAMS;
